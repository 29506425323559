import React, { useState } from 'react'
import styled from 'styled-components';
import pic from "./Assets/picture.jpg";
import { useNavigate } from "react-router-dom";

const LandingScreen = () => {

    const [name, setName] =  React.useState("");

    const navigate = useNavigate();

    const AddUser = () =>{
        localStorage.setItem("sylviaNameCollections", JSON.stringify(name));

        navigate("/todolist");
    }
  return (
    <div>
        <Container>
            <ImageBox>
                {/* <img src={pic} alt="" /> */}
            </ImageBox>
            <h1>Welcome to Sylvia's Todo App</h1>
            <p>Please fill in your name to start creating your tasks for the day</p>
            <InputField
            onChange={(e) =>{
                setName(e.target.value)
            }} 
             placeholder='Enter your Fullname' />
           {
            name !== "" ? (
                <Button onClick={AddUser} bg = "black">Let's Go</Button>
            ) : (
                <Button bg = "silver">Let's Go</Button>
            )
           }
        </Container>
    </div>
  )
}

export default LandingScreen;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    h1{
        margin: 10px;
        text-align: center;
        @media screen and (max-width: 425px) {
        font-size: 20px;
    }
    }
    p{
        margin: 10px;
        text-align: center;
    }
    span{
        color: grey;
        margin-top: 10px;
    }
`;
const InputField = styled.input`
    height: 50px;
    width: 400px;
    border: none;
    padding-left: 10px;
    border-radius: 5px;
    outline-color: #dcd9f8;
    @media screen and (max-width: 425px) {
        width: 280px;
    }
`;
const Button = styled.button<{bg: string}>`
    height: 50px;
    width: 350px;
    margin-top: 20px;
    border: none;
    outline: none;
    background-color: ${(props) => props.bg};
    color: white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    transition: all 350ms;
    :hover{
        transform: scale(0.96);
    }
    @media screen and (max-width: 425px) {
        width: 250px;
    }
`;
const ImageBox = styled.div`
    width: 40px;
    height: 30px;
    /* background-color: red; */
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;