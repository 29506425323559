import React, { useState } from 'react'
import styled from 'styled-components';

interface IData{
  todo: string;
  id: number;
  describe: string;
  startTime: string;
  endTime: string;
  status: Boolean
}

const TodoList = () => {

  // States for todo tasks
  const [ todoValue, setTodoValue] = React.useState("");
  const todoval = todoValue.trim();

  // States for description
  const [ describeValue, setDescribeValue] = React.useState("");
  const describeVal = describeValue.trim();

  // States for time and date:
  const [startDate, setStartDate] = React.useState("");

  // States for end Date and time
  const [endDate, setEndDate] = React.useState("");

  // States for description of tasks to show:
  const [describeTask, setDescribeTask] = React.useState<Boolean>(false);

  // States for onclicking of description button:
  const [addDescribe, setAddDescription] = React.useState(false)

  // states for changing edit:
  const [edit, setEdit] = React.useState(-1);

  // states for datas of the tasks
  const [data, setData] = React.useState<IData[]>([]);

  // States for marking done of a task:
  const [done, setDone] = React.useState<Boolean>(false);

   // Marking a task done:
   const TaskDone = () =>{
    setDone(!done)
  }
  
  // States for date and time task was created;
  const [dateState, setDateState] = useState<any>();
  const [timeState, setTimeStates] = useState<any>();

  // Deleting a task:
  const DeleteTask = (id: number) =>{
    let myFilter = data.filter((el) => el.id !== id);
    setData(myFilter);
  };

 
  // Change state of id:
  const changeState = (id: number) =>{
    setEdit(id);
} 




  // For the task description button to show:
  const DescriptionPopUp = () =>{
    setDescribeTask(!true)
  }

  // Adding description on clicking the describe button:
  const AddDescription = () =>{
    setAddDescription(!addDescribe)
  }

  const localData = JSON.parse(
    window.localStorage.getItem("sylviaNameCollections")  || "",
  );

  let rand = data.length + 1;
  
  // Creating or adding a task:
  const AddTask = () =>{

    // Sort Algorithm:
    const SortTask = (x: any) =>{
      return (a: any, b: any) =>{
        if (a[x] < b[x]) {
          return a[x]
        } else if (a[x] > b[x]) {
          return -1
        }
          return 0;
      }
    }
    // Get the day and time it was created
    let dates : any = new Date().toDateString();
    setDateState(dates)

    // Get the time it was created:
    let hr: any = new Date().getHours();
    let min: any = new Date().getMinutes();
    let amPM: any = hr >= "12" ? "pm" : "am"
    setTimeStates(`${hr}:${min} ${amPM}`)
    
    setData((prev) => [...prev, {
      id: rand,
      todo: todoval,
      describe: describeVal,
      startTime: startDate,
      endTime: endDate,
      status: false,
      // Arranging the task according to how they were created - recent ones at the top:
    }].sort(SortTask("id")));
  };

  return (
    <div>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
            <h2>Welcome {localData.toUpperCase()}</h2>
            <p>Let's create the task for today</p>
            <InputField
            onChange={(e) =>{
                setTodoValue(e.target.value)
            }} 
             placeholder='Enter your task'/> 
              {/* For describe task to show onclicking the button */}
              {
            addDescribe ? <InputField
            onChange={(e) =>{
              setDescribeValue(e.target.value)
            }}
            placeholder='Describe your task' /> : null
           }
            {/* To add Date and time */}
            <DateHold>
            <OneDate>
                Proposed Task Start Time: <DateInput 
                onChange={(e) =>{
                  setStartDate(e.target.value)
                }}
                type = "date"
                 /> 
                
            </OneDate>
            -
           <OneDate>
                Proposed Task End Time:  <DateInput 
                onChange={(e) =>{
                  setEndDate(e.target.value)
                }}
                type="date" /> 
           </OneDate>
            </DateHold>
             
             {/* For description button to show */}
            {
              todoval !== "" ? (
                <Button onClick={AddDescription} bg = "silver">Add Description</Button>
            ) : (
                null
            )
           }
          
            {/* For submit button to add task */}
           {
            todoval !== "" ? (
                <Button onClick={AddTask} bg = "black">Submit</Button>
            ) : (
                <Button bg = "silver">Submit</Button>
            )
           }
           <br /> 
           <br />
           <br />
           <h3>All Task</h3>
           <CardHold>
        {
          data.map((props) =>(
            <Card key={props.id}>
              {
                done && props.id ? (
                  <input defaultValue={props.todo} />
                ) :  <Tittle>
                  <p>Task is: </p>
                  <span>{props.todo}</span></Tittle>
              }

              <TaskDescribe>
                <p>Brief Note: </p>
                {props.describe}
              </TaskDescribe>

                <TaskTime>
                  <p>This task was created on: <span>{dateState}</span> at <span>{timeState}</span> </p>
                    
                  <p>Start-Time: <span>{props.startTime}</span></p>
                  <p>End-Time: <span>{props.endTime}</span></p>
                </TaskTime>
           
            <ButtonHold>
              {/* Edit Button */}
              <DoneButton point='pointer' col='black' bg='#E5E5E5'
              onClick={() =>{
                changeState(props.id)
              }}
              >Edit</DoneButton>

              {/* Done Button */}
              {
             done ?   (
                  <DoneButton point='not-allowed' col='white' bg='green'>Done</DoneButton>
                  ) : (
                    <DoneButton point='pointer' col='black' bg='#E5E5E5'
                    onClick={() => {
                      TaskDone()
                      changeState(props.id)
                    }}
                    >Undone</DoneButton>
                )
              }

              {/* Delete Button */}
              <DoneButton point='pointer' col='black' bg='#E5E5E5'
              onClick={() =>{
                DeleteTask(props.id);
              }}
              >Delete</DoneButton>
            </ButtonHold>
          </Card>
          ))
        }
           </CardHold>
        </Container>
    </div>
  )
}

export default TodoList;

const Container = styled.div`
    display: flex;
    align-items: center;
    /* height: 100vh; */
    flex-direction: column;
    h1{
      margin: 0;
    }
    span{
      color: gray;
      margin-top: 10px;
    }
`;
const InputField = styled.input`
    height: 50px;
    width: 400px;
    border: none;
    padding-left: 10px;
    border-radius: 5px;
    outline-color: #dcd9f8;
    margin: 5px;
    @media screen and (max-width: 425px) {
        width: 280px;
    }
`;
const Button = styled.button<{bg: string}>`
    height: 50px;
    width: 350px;
    margin-top: 20px;
    border: none;
    outline: none;
    background-color: ${(props) => props.bg};
    color: white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    transition: all 350ms;
    :hover{
        transform: scale(0.96);
    }
    @media screen and (max-width: 425px) {
        width: 250px;
    }
`;
const Card = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  /* height: 100px; */
  width: 400px;
  background-color: white;
  display: flex;
  align-items: left;
  flex-direction: column;
  text-align: left;
  padding: 30px;
  border-radius: 5px;
  margin: 5px;
  @media screen and (max-width: 320px) {
    width: 250px;
  }
  span{
    color: grey;
  }
`;
const TaskDescribe = styled.div`
  color: gray;
  font-weight: bold;
`;
const ButtonHold = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Tittle = styled.div`
  color: gray;
  font-weight: bold;
`;
const CardHold = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const DateHold = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  @media screen and (max-width: 425px) {
    justify-content: center;
    width: 300px;
  }
`;
const OneDate = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 200px;
  margin: 5px;
   @media screen and (max-width: 425px) {
    /* width: 280px; */
  }
`;
const DateInput = styled.input`
   height: 50px;
    width: 180px;
    border: none;
    border-radius: 5px;
    outline-color: #dcd9f8;
`;
const TaskTime = styled.div`
   color: gray;
   font-weight: bold;
`;
const DoneButton = styled.button<{bg: string; point: string, col: string}>`
  background-color: ${(props) => props.bg};
  border-radius: 5px;
  color: ${(props) => props.col};
  cursor: ${(props) => props.point};
  margin-right: 5px;
  height: 30px;
  width: 90px;
  margin-top: 20px;
`;